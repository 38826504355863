<template lang="html">
  <section class="contact">
    <bannerHead dataTitle="Contacto"></bannerHead>
    <breacrumbs dataTitle="Contacto"></breacrumbs>
    <div class="others ">
      <div class="container">
        <p class="title-line -small">Datos de contacto:</p>
        <div class="grid-3">
          <a href="mailto:admin.personal@ufv.es" class="grid box-blue -darkBig"
            title="Correo electrónico administración">
            <img src="/img/icons/icon-email.svg" alt="Icono email">
            <p class="title">Administración de Personas y Relaciones Laborales</p>
            <span>
              admin.personal@ufv.es
            </span>
          </a>
          <a href="mailto:serviciodeprevencion@ufv.es" class="grid box-blue -dark"
            title="Correo electrónico seguridad. salud y bienestar">
            <img src="/img/icons/icon-email.svg" alt="icono email">
            <p class="title">Seguridad, Salud
              y bienestar
            </p>
            <span>serviciodeprevencion@ufv.es</span>
          </a>
          <a href="mailto:talentoufv@ufv.es" class="grid box-blue -medium"
            title="Correo electrónico selección y talento">
            <img src="/img/icons/icon-email.svg" alt="icono email">
            <p class="title">Selección y Talento
            </p>
            <span>talentoufv@ufv.es</span>
          </a>
          <!-- <a href="mailto:sistemas@ufv.es" class="grid box-blue -light">
            <img src="/img/icons/icon-email.svg" alt="">
            <p class="title"> Sistemas
            </p>
            <span>sistemas@ufv.es</span>
          </a> -->
        </div>
      </div>

    </div>
  </section>
</template>

<script lang="js">
  import bannerHead from '@/components/banner-head.vue'
  import breacrumbs from '@/components/breacrumbs.vue'
  import { formStore } from '@/stores/forms'
  import { contentStore } from '@/stores/contents'
  import 'vue3-lottie/dist/style.css'
  import loadingAnimation from '@/lotties/loading.json'
  // import axios from 'axios'
  import useValidate from "@vuelidate/core";
  import { reactive, computed } from "vue";
  import { required, email, helpers } from "@vuelidate/validators";
  const requiredMsg = helpers.withMessage('Este campo es obligatorio', required);
  const phoneCheck = helpers.regex(/^[0-9]{7,9}$/);
  const storeForm = formStore();
  const storeContent = contentStore();
export default {
  name: 'contact',
  props: [],
  components: {
    bannerHead,
    breacrumbs
  }
}
</script>

<style scoped lang="scss">
  option {
    cursor: pointer;
  }

  .mss-error {
    color: orange;
    font-weight: 400;
    font-size: 16px;
    margin-top: 5px;
    display: inline-block;
  }

  .class-error {
    border: 1px solid orange;
  }
</style>